const SearchPatientFormValues = {
  labNumber: "",
  patientId: "",
  lastName: "",
  firstName: "",
  dateOfBirth: "",
  guid: "",
  gender: "",
};

export default SearchPatientFormValues;
