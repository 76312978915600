export const sampleTypeTestsStructure = {
  sampleTypeId: "",
  panels: [
    {
      name: "",
      testMaps: "",
      panelId: "1",
      panelOrder: 0,
    },
  ],
  tests: [
    {
      id: "",
      name: "",
      userBenchChoice: false,
    },
  ],
};
